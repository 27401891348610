import { DEFAULT_URL } from '../config/config'
import { useRouter } from 'next/router'
import Button from '../components/base/button/Button'
import Card from '../components/base/card/Card'
import CardText from '../components/base/card/CardText'
import Column from '../components/base/grid/Column'
import DashboardLayout from '../components/layouts/DashboardLayout'
import Row from '../components/base/grid/Row'
import SearchTextField from '../components/dashboard/search/SearchTextField'
import ThemeService from '../services/ThemeService'

const Custom403 = () => {
    // This page is not translated because nextjs error page doesn't support getServerSideProps
    // And i18n in nextjs requires getServerSideProps / getStaticProps...

    const router = useRouter()

    const getStyles = () => {
        return (
            typeof window !== 'undefined' && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: ThemeService.getThemeStyleSheetByHostname(window.location.hostname)
                    }}
                />
            )
        )
    }

    const redirectToHomepage = () => {
        router.replace(DEFAULT_URL)
        router.reload()
    }

    return (
        <DashboardLayout
            showSidebar={false}
            appendHeaders={getStyles()}
            primaryTitle='layout'
            title='Nemáte oprávnění / You are not authorized'
            showPosts={false}
            appBarContent={<SearchTextField />}
        >
            <Card>
                <CardText className='p-6 pr-20'>
                    <Row justify='center'>
                        <Column cols={7}>
                            <img src='/shared/robot-with-error.png' alt='403' />
                        </Column>
                        <Column cols={5} className='flex flex-col justify-center items-center text-center'>
                            <h2 className='text-4xl font-medium text-gray-900 tracking-wide sm:text-6xl'>Chyba 403</h2>
                            <p className='mt-6 text-base text-gray-500'>K provedení této akce</p>
                            <p className='text-base text-gray-500'>nemáte oprávnění.</p>
                            <Button className='mt-8' onClick={redirectToHomepage}>
                                Domovská stránka
                            </Button>
                        </Column>
                    </Row>
                </CardText>
            </Card>
        </DashboardLayout>
    )
}

export default Custom403
